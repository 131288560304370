<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-form @submit.prevent="doScaLogin()">
      <v-card max-width="600px" class="mx-auto my-12 px-10 py-6" elevation="6">
        <v-card-title class="px-4 mt-6 mb-8">
          <div>{{ `心理测量中心 - ${loginModeTitle}` }}</div>
          <v-spacer></v-spacer>
          <v-btn-toggle
            v-model="loginModeToggle"
            mandatory
            dense
            color="primary"
          >
            <v-btn>{{ toggleBtnLabels[0] }}</v-btn>
            <v-btn>{{ toggleBtnLabels[1] }}</v-btn>
          </v-btn-toggle>
        </v-card-title>
        <v-card-text class="px-8">
          <div v-if="isInUserMode">
            <v-text-field
              outlined
              dense
              v-model="userLoginKey"
              :label="loginKeyLabel"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              type="password"
              v-model="userLoginPwd"
              label="密码"
            ></v-text-field>
          </div>
          <div v-if="isInTestPwdMode">
            <v-text-field
              outlined
              dense
              label="输入测量码"
              v-model="testLoginPwd"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-spacer></v-spacer>
          <v-btn type="submit" depressed color="primary px-8">
            {{ loginModeTitle }}
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-10"></v-divider>
        <v-card-text>
          <div>{{ loginPanelTitle }}</div>
          <div>{{ copyright }}</div>
          <div class="app-version">{{ userCustInfo.appVersion }}</div>
        </v-card-text>
      </v-card>
    </v-form>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import { fetchSingletonCustInfo } from "@/api/user";
import { scaLogin, scaAutoLogin, scaUserLogin } from "@/api/sca";
import { scaLoginMode } from "@/utils/constants/sca";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox
  },

  props: {
    mode: {
      type: String
    },
    scacode: {
      type: String
    }
  },

  data() {
    return {
      isLoading: false,
      loginMode: this.mode || scaLoginMode.user.value,
      loginModeToggle:
        (scaLoginMode[this.mode] && scaLoginMode[this.mode].id) || 0,
      userLoginKey: "",
      userLoginPwd: "",
      testLoginPwd: "",
      isLoginFailed: false,
      userCustInfo: {
        displayName: "",
        description: "",
        website: "",
        landingTitle: "",
        isSingleton: true
      },
      errorMsg: ""
    };
  },

  watch: {
    mode(newVal) {
      this.loginMode = newVal;
    },
    loginModeToggle() {
      if (this.isInUserMode) {
        this.$router.push({
          name: "scalogin",
          query: { mode: scaLoginMode.testPwd.value }
        });
      } else if (this.isInTestPwdMode) {
        this.$router.push({
          name: "scalogin",
          query: { mode: scaLoginMode.user.value }
        });
      }
    }
  },

  computed: {
    loginPanelTitle() {
      if (this.userCustInfo.landingTitle) {
        return this.userCustInfo.landingTitle;
      } else if (this.userCustInfo.displayName) {
        return this.userCustInfo.displayName;
      } else if (!this.userCustInfo.isSingleton) {
        // 多用户的情况下才叫这个名字
        return "心理健康测评系统 3.0";
      }
      return "";
    },
    loginModeTitle() {
      return `${scaLoginMode[this.loginMode].text}登录`;
    },
    copyright() {
      return `Copyright ${new Date().getFullYear()}`;
    },
    loginKeyLabel() {
      return "用户名 / 识别号";
    },
    isInUserMode() {
      return this.loginMode === scaLoginMode.user.value;
    },
    isInTestPwdMode() {
      return this.loginMode === scaLoginMode.testPwd.value;
    },
    toggleBtnLabels() {
      return [scaLoginMode.user.text, scaLoginMode.testPwd.text];
    }
  },

  methods: {
    resetInputData() {
      this.userLoginKey = "";
      this.userLoginPwd = "";
      this.testLoginPwd = "";
      this.isLoginFailed = false;
    },
    async loadCustInfoIfSingleton() {
      try {
        this.isLoading = true;
        this.userCustInfo = await fetchSingletonCustInfo();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async doScaLogin() {
      // 首先清空上次的缓存
      this.$store.dispatch("sca/clearScaState");
      // 登录
      if (this.isInUserMode) {
        await this.doUserLogin();
      } else if (this.isInTestPwdMode) {
        await this.doTestPwdLogin();
      }
    },
    async doUserLogin() {
      if (this.userLoginKey) {
        try {
          this.isLoading = true;
          let loginUserState = await scaUserLogin(
            this.userLoginKey,
            this.userLoginPwd
          );
          if (loginUserState) {
            this.setScaLoginState(loginUserState);
            this.$router.push({ name: "scainfoinput" });
          } else {
            this.errorMsg = "用户名或密码错误";
          }
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    },
    async doTestPwdLogin(isLoginFromQrCode) {
      try {
        this.isLoading = true;
        let loginUserState = {};
        if (isLoginFromQrCode && this.scacode) {
          loginUserState = await scaAutoLogin(this.scacode);
        } else if (!isLoginFromQrCode && this.testLoginPwd) {
          loginUserState = await scaLogin(this.testLoginPwd);
        }
        if (loginUserState) {
          this.setScaLoginState(loginUserState);
          this.$router.push({ name: "scainfoinput" });
        } else {
          this.errorMsg = "测量码错误";
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    setScaLoginState(loginState) {
      this.$store.dispatch("sca/initScaState", {
        regionGuid: loginState.customerGuid,
        userDispName: loginState.customerDispName,
        nodeGuid: loginState.manageNodeGuid,
        nodeName: loginState.manageNodeName,
        openLbGroupGuid: loginState.openLbGroupGuid || "",
        personGuid: loginState.loggedInPersonGuid || ""
      });
      // if (loginState.testLbList && loginState.testLbList.length) {
      //   this.$store.dispatch("sca/setTestLbList", loginState.testLbList);
      // }
    }
  },

  created() {
    // 自动登录为设定的 node
    if (this.scacode) {
      this.doTestPwdLogin(true);
    } else {
      this.loadCustInfoIfSingleton();
    }
  }
};
</script>

<style lang="scss" scoped>
.app-version {
  text-align: right;
  font-size: 6px;
  color: #bbb;
}
</style>
